import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { UPSELL_ROUTING } from '@features/upsell/routes/upsell.routing';
import { Store } from '@ngrx/store';
import { allowUpsellWithoutPayment } from '@stores/feature-flags/feature-flags.selectors';
import { hasPayment, partnerId } from '@stores/partner/partner.selectors';
import { topPlanId } from '@stores/plan/plan.selectors';
import { isChild, isLegalGuardian } from '@stores/profile/profile.selectors';
import {
  purchasableArticles,
  purchasableArticle as purchaseStorePurchasableArticle,
} from '@stores/purchase/purchase.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { UpsellWebService } from '@webservices/upsell.webservice';
import { ScrollToConfigOptions, ScrollToService } from '@wizbii/ngx-scroll-to';
import { filter, map, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpsellConfigService {
  readonly #store = inject(Store);
  readonly #upsellWebService = inject(UpsellWebService);
  readonly #router = inject(Router);
  readonly #scrollToService = inject(ScrollToService);
  readonly #allowUpsellWithoutPaymentSig = this.#store.selectSignal(allowUpsellWithoutPayment);
  readonly #hasPaymentSig = this.#store.selectSignal(hasPayment);

  readonly upsellConfig$ = selectIfDefined(this.#store, partnerId).pipe(
    switchMap((id) => this.#upsellWebService.getUpsellConfig(id))
  );

  readonly upsellConfigSig = toSignal(this.upsellConfig$, { initialValue: null });

  readonly purchasableArticleSig = toSignal(
    selectIfDefined(this.#store, topPlanId).pipe(
      filter(Boolean),
      switchMap((id) => this.#store.select(purchaseStorePurchasableArticle(id)))
    ),
    { initialValue: null }
  );

  readonly guardianPurchasableArticleSig = toSignal(
    selectIfDefined(this.#store, isLegalGuardian).pipe(
      filter((isLegalGuardian) => !!isLegalGuardian),
      switchMap(() => this.#store.select(purchasableArticles)),
      filter((purchasableArticles) => Object.values(purchasableArticles).length > 0),
      map((purchasableArticles) => Object.values(purchasableArticles)[0].toPlanId),
      switchMap((id) => this.#store.select(purchaseStorePurchasableArticle(id)))
    ),
    { initialValue: null }
  );

  readonly isChildSig = this.#store.selectSignal(isChild);

  onCtaClick(): void {
    const isBankingProductsEmpty = this.upsellConfigSig()?.bankingProducts.length === 0;
    const hasOnlyOneBankingProduct = this.upsellConfigSig()?.bankingProducts.length === 1;
    const hasNoPayment = this.#hasPaymentSig() === false;

    const scrollableToPurchaseContent =
      this.#allowUpsellWithoutPaymentSig() === true || (hasNoPayment && hasOnlyOneBankingProduct);

    if (scrollableToPurchaseContent) this.#scrollToSubscribeButton();
    else if (isBankingProductsEmpty) this.#redirectToPayment();
    else this.#redirectToUpsellBankingProducts();
  }

  #redirectToUpsellBankingProducts(): void {
    this.#router.navigate([FEATURES_ROUTING.upsell, UPSELL_ROUTING.bankingProducts]);
  }

  #redirectToPayment(): void {
    this.#router.navigate([FEATURES_ROUTING.upsell, UPSELL_ROUTING.purchasePage]);
  }

  #scrollToSubscribeButton(): void {
    const scrollConfig: ScrollToConfigOptions = {
      easing: 'easeOutElastic',
      target: 'upsellCardProduct',
      offset: -200,
    };

    this.#scrollToService.scrollTo(scrollConfig);
  }
}
